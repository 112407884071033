import React from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { List, ListItemText } from '@material-ui/core';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import MegaphoneIcon from '../../images/news.png';
import ListIcon from '@material-ui/icons/List';
import './Home.css';

let isNotVisible = true;

function createData(key, date, content) {
  return { key, date, content };
}

/* Only stays 4 new Notes, we take out the oldest note and put it in pastNotices array */
const newNotices = [
  createData(3, '11-Nov-2020', 'CAPM: Resumen capítulo 13 disponible'),
  createData(2, '3-Nov-2020', 'CAPM: Resumen capítulo 12 disponible'),
  createData(1, '26-Oct-2020', 'CAPM: Resumen capítulo 11 disponible'),
  createData(0, '23-Oct-2020', 'CAPM: Resumen capítulo 10 disponible'),
]

const pastNotices = [
  createData(15, '8-Oct-2020', 'CAPM: Resumen capítulo 9 disponible'),
  createData(14, '24-Sept-2020', 'Ciencia Cognitiva: Ejemplo Árboles de Decisión'),
  createData(13, '24-Sept-2020', 'CAPM: Resumen capítulo 8 disponible'),
  createData(12, '20-Sept-2020', 'CAPM: Resumen capítulo 7 disponible'),
  createData(11, '14-Sept-2020', 'Ciencia Cognitiva: Práctica 1 Machine Learning'),
  createData(10, '13-Sept-2020', 'CAPM: Resumen capítulo 6 disponible'),
  createData(9, '10-Sept-2020', 'DATAMINING: Código R Primer Examen Parcial'),
  createData(8, '9-Sept-2020', 'DATAMINING: Guía de estudio Primer Parcial'),
  createData(7, '6-Sept-2020', 'CAPM: Resumen capítulo 5 disponible'),
  createData(6, '2-Sept-2020', 'DATAMINING: Repaso Práctica Clusters'),
  createData(5, '2-Sept-2020', 'DATAMINING: Práctica Autos Clusters en R'),
  createData(4, '28-Ago-2020', 'CAPM: Resumen capítulos 3 y 4 disponibles'),
  createData(3, '28-Ago-2020', 'DATAMINING: Práctica FB Clusters en R'),
  createData(2, '12-Ago-2020', 'CAPM: Resumen y Quizlet capítulos 1 y 2 disponibles'),
  createData(1, '10-Ago-2020', 'Primer día de clases'),
  createData(0, '6-Ago-2020', 'Fabshub en línea'),
]

export default function HomeComponent(props) {

  const handleClick = (event) => {
    let showPastNews = document.getElementById('pastNews');
    if (isNotVisible) {
      showPastNews.style.display = 'block';
      isNotVisible = false;
    } else {
      showPastNews.style.display = 'none';
      isNotVisible = true;
    }
  }

  return (
    <div className='Home'>
      <br />
      <br />
      <Grid container>
        <Grid item xs={2} sm={1}></Grid>
        <Grid item xs={12} sm={6}>
          <img
            alt='Megáfono'
            src={MegaphoneIcon}
            height={75}
            align='left'
          />
          <br />
          <Typography variant='h2' align='center'>
            Últimas Noticias
          </Typography>
          <Divider />
          <br />
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableBody>
                {newNotices.map((notice) => (
                  <TableRow key={notice.key}>
                    <TableCell>
                      <Typography variant='body1'>{notice.date}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Box fontStyle='italic'>
                        <Typography variant='body1' >{notice.content}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={3} />
          <Button
            color='secondary'
            style={{ textTransform: 'none' }}
            fullWidth
            variant='outlined'
            onClick={handleClick}
          >
            <ListIcon color='secondary' fontSize='large' />
            Noticias Anteriores
          </Button>
          <Box p={1} />
          <div className='PastNewsConf' id='pastNews'>
            <List
              aria-label='Noticias Pasadas'
              component='nav'
            >
              <Box fontStyle='italic'>
                {pastNotices.map((pastNotice) => (
                  <div>
                    <ListItemText key={pastNotice.key}>
                      {pastNotice.date} - {pastNotice.content}
                    </ListItemText>
                    <Divider />
                  </div>
                ))}
              </Box>
            </List>
          </div>
        </Grid>
        <Grid item xs={2} sm={1}></Grid>
        <Grid item xs={8} sm={4}>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="fcampm"
            options={{ height: 580, width: 500 }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
