import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import './FooterPage.css';

export default function FooterPageComponent() {
  return (
    <footer>
      <Divider />
      <br />
      <Typography variant='body2' color='primary'>
        © 2020-{new Date().getFullYear()} por Fabián Camp Mussa (<a href='mailto:fabiancamp98@gmail.com'>fabiancamp98@gmail.com</a>)
      </Typography>
      <Typography variant='body2' color='primary'>
        Desarrollado con <a className='Footer-link' href='https://es.reactjs.org'>React</a> y <a
        className='Footer-link' href='https://material-ui.com'>Material UI</a>
      </Typography>
    </footer>
  );
}