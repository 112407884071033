import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid, IconButton } from '@material-ui/core';
import { Table, TableBody, TableCell, TableRow, TableContainer } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

/* FIS2 Guides */
import GuiaExamenPrimerFisDos from '../../files/FIS2/guiaDeEstudioPrimerExamen.pdf';
import GuiaExamenSegundoFisDos from '../../files/FIS2/guiaDeEstudioSegundoExamen.pdf';
import GuiaExamenFinalFisDos from '../../files/FIS2/guiaDeEstudioFinalExamen.pdf';
/* OS Guides */
import GuiaExamenPrimeroOs from '../../files/OS/guiaDeEstudioPrimerQuiz.pdf';
import GuiaExamenSegundoOs from '../../files/OS/guiaDeEstudioSegundoQuiz.pdf';
import GuiaExamenTercerOs from '../../files/OS/guiaDeEstudioTercerQuiz.pdf';
import GuiaExamenFinalOs from '../../files/OS/guiaDeEstudioFinalQuiz.pdf';
/* Admin Guides */
import GuiaExamenPrimerAdmin from '../../files/AdministracionProyectos/guiaDeEstudioPrimerExamenAdminProyectos.pdf';
import GuiaExamenSegundoAdmin from '../../files/AdministracionProyectos/guiaDeEstudioSegundoExamenAdminProyectos.pdf';
import GuiaExamenFinalAdmin from '../../files/AdministracionProyectos/guiaDeEstudioFinalExamenAdminProyectos.pdf';
/* FIS3 Guides */
import GuiaExamenPrimerPrimerFis3 from '../../files/FIS3/guiaDeEstudioPrimerExamenReg1FIS3.pdf';
import GuiaExamenPrimerSegundoFis3 from '../../files/FIS3/guiaDeEstudioSegundoExamenReg1FIS3.pdf';
import GuiaExamenSegundoPrimerFis3 from '../../files/FIS3/guiaDeEstudioPrimerExamenReg2FIS3.pdf';
import GuiaExamenSegundoSegundoFis3 from '../../files/FIS3/guiaDeEstudioSegundoExamenReg2FIS3.pdf';
import GuiaExamenFinalFis3 from '../../files/FIS3/guiaDeEstudioFinalExamenFIS3.pdf';
/* TIC Guides */
import GuiaExamenFinalTic from '../../files/TIC/guiaDeEstudioFinalExamenTIC.pdf';
/* CAPM Guides */
import ResumenChap1_2 from '../../files/CAPM/SummaryCh1&2_A01378565.pdf';
import ResumenChap3_4 from '../../files/CAPM/SummaryCh3&4_A01378565.pdf';
import ResumenChap5 from '../../files/CAPM/SummaryCh5_A01378565.pdf';
import ResumenChap6 from '../../files/CAPM/SummaryCh6_A01378565.pdf';
import ResumenChap7 from '../../files/CAPM/SummaryCh7_A01378565.pdf';
import ResumenChap8 from '../../files/CAPM/SummaryCh8_A01378565.pdf';
import ResumenChap9 from '../../files/CAPM/SummaryCh9_A01378565.pdf';
import ResumenChap10 from '../../files/CAPM/SummaryCh10_A01378565.pdf';
import ResumenChap11 from '../../files/CAPM/SummaryCh11_A01378565.pdf';
import ResumenChap12 from '../../files/CAPM/SummaryCh12_A01378565.pdf';
import ResumenChap13 from '../../files/CAPM/SummaryCh13_A01378565.pdf';
/* DATAMINING Files */
import PracticaFbClusters from '../../files/Datamining/RPrograms/practicaFbClusters.r';
import PracticaAutosRStudio from '../../files/Datamining/RPrograms/practicaAutosClusters.r';
import PracticaRepasoCluster from '../../files/Datamining/RPrograms/practica02092020.r';
import GuiaPrimerParcialDataMining from '../../files/Datamining/Guides/guiaPrimerExamenDM.pdf';
import CodigoRPrimerExamen from '../../files/Datamining/RPrograms/codigoRExamenPrimerRegistro.r';
/* COGNITIVE SCIENCE  Files */
import Practica1MachineLearning from '../../files/CognitiveScience/practica1_ml.ipynb';
import EjemploDecisionsTree from '../../files/CognitiveScience/decisions_tree.ipynb';

import './Guide.css';

function createData(key, iconToDisplay, name, date, link, quizlet) {
  return { key, iconToDisplay, name, date, link, quizlet };
}

const fisDosRows = [
  createData(0, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Examen', 'Agosto 8, 2020', GuiaExamenPrimerFisDos, ''),
  createData(1, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Segundo Examen', 'Agosto 8, 2020', GuiaExamenSegundoFisDos, ''),
  createData(2, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Examen Final', 'Agosto 8, 2020', GuiaExamenFinalFisDos, '')
];

const operatingSystemsRows = [
  createData(0, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Quiz', 'Agosto 8, 2020', GuiaExamenPrimeroOs, ''),
  createData(1, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Segundo Quiz', 'Agosto 8, 2020', GuiaExamenSegundoOs, ''),
  createData(2, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Tercer Quiz', 'Agosto 8, 2020', GuiaExamenTercerOs, ''),
  createData(3, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Quiz Final', 'Agosto 8, 2020', GuiaExamenFinalOs, '')
]

const adminProyectosRows = [
  createData(0, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Examen', 'Agosto 8, 2020', GuiaExamenPrimerAdmin, ''),
  createData(1, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Examen', 'Agosto 8, 2020', GuiaExamenSegundoAdmin, ''),
  createData(2, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Examen', 'Agosto 8, 2020', GuiaExamenFinalAdmin, '')
]

const fisTresRows = [
  createData(0, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Examen Registro 1', 'Agosto 8, 2020', GuiaExamenPrimerPrimerFis3, ''),
  createData(1, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Segundo Examen Registro 1', 'Agosto 8, 2020', GuiaExamenPrimerSegundoFis3, ''),
  createData(2, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Examen Registro 2', 'Agosto 8, 2020', GuiaExamenSegundoPrimerFis3, ''),
  createData(3, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Segundo Examen Registro 2', 'Agosto 8, 2020', GuiaExamenSegundoSegundoFis3, ''),
  createData(4, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Examen Final', 'Agosto 8, 2020', GuiaExamenFinalFis3, '')
]

const ticsRows = [
  createData(0, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Examen Final', 'Agosto 8, 2020', GuiaExamenFinalTic, ''),
]

const capmRows = [
  createData(0, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulos 1 & 2 PMBOK', 'Agosto 12, 2020', ResumenChap1_2, 'https://quizlet.com/_8knkah?x=1jqt&i=20gs71'),
  createData(1, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulos 3 & 4 PMBOK', 'Agosto 28, 2020', ResumenChap3_4, ''),
  createData(2, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 5 PMBOK', 'Septiembre 6, 2020', ResumenChap5, ''),
  createData(3, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 6 PMBOK', 'Septiembre 13, 2020', ResumenChap6, ''),
  createData(4, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 7 PMBOK', 'Septiembre 20, 2020', ResumenChap7, ''),
  createData(5, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 8 PMBOK', 'Septiembre 24, 2020', ResumenChap8, ''),
  createData(6, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 9 PMBOK', 'Octubre 8, 2020', ResumenChap9, ''),
  createData(7, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 10 PMBOK', 'Octubre 23, 2020', ResumenChap10, ''),
  createData(8, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 11 PMBOK', 'Octubre 26, 2020', ResumenChap11, ''),
  createData(9, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 12 PMBOK', 'Noviembre 3, 2020', ResumenChap12, ''),
  createData(10, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Resumen Capítulo 13 PMBOK', 'Noviembre 10, 2020', ResumenChap13, ''),
]

const dataminingRows = [
  createData(0, <InsertDriveFileIcon color='secondary' fontSize='large' />, 'Práctica Fb Clusters en RStudio', 'Agosto 28, 2020', PracticaFbClusters, ''),
  createData(1, <InsertDriveFileIcon color='secondary' fontSize='large' />, 'Práctica Autos en RStudio', 'Septiembre 2, 2020', PracticaAutosRStudio, ''),
  createData(2, <InsertDriveFileIcon color='secondary' fontSize='large' />, 'Repaso Práctica Clusters en RStudio', 'Septiembre 2, 2020', PracticaRepasoCluster, ''),
  createData(3, <PictureAsPdfIcon color='secondary' fontSize='large' />, 'Guía de Estudio Primer Parcial', 'Septiembre 9, 2020', GuiaPrimerParcialDataMining, ''),
  createData(4, <InsertDriveFileIcon color='secondary' fontSize='large' />, 'Código R Primer Examen Parcial', 'Septiembre 10, 2020', CodigoRPrimerExamen, ''),
]

const cognitiveScienceRows = [
  createData(0, <InsertDriveFileIcon color='secondary' fontSize='large' />, 'Práctica 1 Machine Learning', 'Septiembre 14, 2020', Practica1MachineLearning, ''),
  createData(1, <InsertDriveFileIcon color='secondary' fontSize='large' />, 'Ejemplo Árboles de Decisión', 'Septiembre 14, 2020', EjemploDecisionsTree, ''),
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function GuideComponent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Guide tabs'
        className={classes.tabs}
      >
        <Tab label='Análisis y modelo de sistemas de software' {...a11yProps(0)} />
        <Tab label='Sistemas Operativos' {...a11yProps(1)} />
        <Tab label='Admin de Proyectos' {...a11yProps(2)} />
        <Tab label='Pruebas y Calidad de Software' {...a11yProps(3)} />
        <Tab label='Tendencias Tecnológicas en Plataformas de TIC' {...a11yProps(4)} />
        <Tab label='CAPM' {...a11yProps(5)} />
        <Tab label='Datamining' {...a11yProps(6)} />
        <Tab label='Ciencia Cognitiva' {...a11yProps(7)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Análisis y modelo de sistemas de software
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {fisDosRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Sistemas Operativos
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {operatingSystemsRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Administración de Proyectos
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {adminProyectosRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Pruebas y Calidad de Software
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {fisTresRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Tendencias Tecnológicas en Plataformas de TIC Corporativas
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {ticsRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Administración Sistemática - CAPM
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {capmRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <a className='Guide-link' href={element.quizlet}>
                            Quizlet
                            <ExitToAppIcon fontSize='medium' />
                          </a>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Datamining
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {dataminingRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Ciencia Cognitiva
          </Typography>
        </Box>
        <Typography variant='h6' align='left'>
          Si descargas el archivo .ipynb con la extensión <br />
          .ipynb.text cambiala por .ipynb para que lo puedas utilizar.
        </Typography>
        <br />
        <Grid container>
          <Grid item xs={2} sm={1} />
          <Grid item xs={22} sm={11} >
            <TableContainer component={Paper}>
              <Table
                aria-label='simple table'
              >
                <TableBody>
                  {cognitiveScienceRows.map((element) => (
                    <TableRow key={element.key}>
                      <TableCell>
                        {element.iconToDisplay}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {element.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label='descargar'
                          size='large'
                          href={element.link}
                          download
                        >
                          <GetAppIcon fontSize='inherit' />
                        </IconButton>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}