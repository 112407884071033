import React from 'react';
import { Toolbar } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';


export default function NavBarComponent(props) {

  return (
    <AppBar position='static'>
      <Toolbar>
        
        {/* Logo Button */}
        <Link
          to='/'
          style={{ color: "white", textDecoration: 'none' }}
        >
          <Typography variant='h6'>
            fabshub.net
          </Typography>
        </Link>
        <Typography style={{ flexGrow: 1 }}></Typography>

        {/* Home Button */}
        <Link
          to='/'
          style={{ color: "white", textDecoration: 'none' }}
        >
          <Button color='inherit' style={{ textTransform: "none" }}>
            Inicio
          </Button>
        </Link>

        {/* About Me Button */}
        <Link
          to='/about'
          style={{ color: "white", textDecoration: 'none' }}
        >
          <Button color='inherit' style={{ textTransform: "none" }}>
            Sobre Mí
          </Button>
        </Link>

        {/* Writings Button */}
        <Link
          to='/writings'
          style={{ color: "white", textDecoration: 'none' }}
        >
          <Button color='inherit' style={{ textTransform: "none" }}>
            Escritos
          </Button>
        </Link>

        {/* Study Guides Button */}
        <Link
          to='/guides'
          style={{ color: "white", textDecoration: 'none' }}
        >
          <Button color='inherit' style={{ textTransform: "none" }}>
            Guías de Estudio
          </Button>
        </Link>

        {/* Contact Button */}
        <Link
          to='/contact'
          style={{ color: "white", textDecoration: 'none' }}
        >
          <Button color='inherit' style={{ textTransform: "none" }}>
            Contacto
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
}