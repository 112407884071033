import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function WritingComponent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Writing tabs"
        className={classes.tabs}
      >
        <Tab label="Así es el amor" {...a11yProps(0)} /> {/* 18-jul-2020 */}
        <Tab label="Cansancio" {...a11yProps(1)} /> {/* 11-jul-2020 */}
        <Tab label="La persona de la que me enamoré" {...a11yProps(2)} /> {/* 28-jun-2019 */}
        <Tab label="Pensamientos de madrugada" {...a11yProps(3)} /> {/* 29-mayo-2019 */}
        <Tab label="Este corazón herido" {...a11yProps(4)} /> {/* 25-oct-2018 */}
        <Tab label="La poesía se ha dejado" {...a11yProps(5)} /> {/* 11-oct-2018 */}
        <Tab label="Qué bonito es el amor" {...a11yProps(6)} /> {/* 10-oct-2018 */}
        <Tab label="Oda al amor" {...a11yProps(7)} /> {/* 8-oct-2018 */}
        <Tab label="Rindo esta, mi humilde declaración" {...a11yProps(8)} /> {/* 17-sept-2018 */}
        <Tab label="Y aquí vamos de nuevo..." {...a11yProps(9)} /> {/* 3-ago-2018 */}
        <Tab label="No he cambiado" {...a11yProps(10)} /> {/* 27-may-2018 */}
        <Tab label="Sin título" {...a11yProps(11)} /> {/* 28-dic-2017 */}
        <Tab label="Un caballero empedernido con el amor" {...a11yProps(12)} /> {/* 18-nov-2017 */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Así es el amor
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Julio 18, 2020.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          El amor no es un mensaje bello. <br />
          El amor no son conversaciones llenas de te quiero. <br />
          El amor no son promesas de vamos a vernos. <br />
          El amor no es solo buscar cuando necesitas un consuelo. <br />
          El amor no son palabras vacías que se las lleva el viento. <br />
          El amor es apoyar en tiempos buenos y adversos. <br />
          El amor es sentir con el corazón todos esos te quiero. <br />
          El amor es demostrar mediante hechos. <br />
          Así es el amor, no es ni blanco, ni negro. <br />
          Así es el amor, a veces malo, a veces bueno. <br />
          Así es el amor, o eso es lo que yo creo. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Cansancio
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Julio 11, 2020.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Llega el punto en el que uno se comienza a agotar. <br />
          Se cansa de intentar, de hablar y, en especial, de estar. <br />
          Cada vez es más difícil en ti pensar <br />
          Te estoy comenzando a olvidar. <br /><br />

          Doloroso será un día despertar. <br />
          E indiferencia encontrar <br />
          En donde tu deberías estar. <br />
          Pero admitámoslo, nunca vas a regresar. <br /><br />

          El miedo me impide actuar. <br />
          Me es difícil, incluso un mensaje mandar. <br />
          Porque no sé si vas a contestar. <br />
          O simplemente me vas a ignorar. <br /><br />

          Es cansado decirte la falta que me haces. <br />
          Es cansado decirte cada día lo hermosa que eres. <br />
          Es cansado ver tu retrato, mensajearte y esperar un “te amo”. <br />
          Es cansado seguir ahí, pero te amo tanto que estoy dispuesto a aguantarlo. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            La persona de la que me enamoré
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Junio 28, 2019.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Siempre te veo en tu reino del sabor y aroma. <br />
          Aunque no repares en esta, mi persona. <br />
          Y por más tonto que parezca. <br />
          Nuestro encuentro aún no llega. <br /><br />

          Yo te ubico, mas no te conozco. <br />
          Y, a pesar de ello. <br />
          Quisiera ser aquello. <br />
          Y con el paso del tiempo. <br />
          Poder decirte que ¡te quiero! <br /><br />

          Estoy en ese punto. <br />
          Que pienso en lo que haríamos juntos. <br />
          Muchos dirán que todo es muy apresurado. <br />
          Aunque yo diga que el momento es el adecuado. <br /><br />

          Pensarán, ¿como puedes enamorarte de esa manera? <br />
          Si no conoces a la que será tu compañera. <br />
          Yo no decidí enamorarme. <br />
          Y que feliz estoy que de ella fue. <br />
          La persona de la que me enamoré. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Pensamientos de madrugada
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Mayo 29, 2019.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Vuelve a mi aquel sentimiento que por ti sentí. <br />
          Enterrado en lo más profundo de mí. <br />
          Pensé que de él me había librado al fin. <br />
          Que equivocado al pensar que fue así. <br /><br />

          Alejarme es lo que mi mente diría. <br />
          Mas mi corazón un vuelco daría. <br />
          Ya que si me alejara te perdería. <br />
          Y jamás volverías a ser mi amiga. <br /><br />

          Tal vez sea cuestión de un día, quizás dos o tres. <br />
          Tal vez nunca supere mi cobardía al no decirte todo aquel día. <br />
          En el que vestías esa pieza que acentuaba toda tu belleza. <br />
          Como me gustaría que fueses algo más que solamente mi amiga. <br /><br />

          Es hora de despertar y comenzar a madurar. <br />
          Y renunciar a todas esas mil y una ideas de como te iba a conquistar. <br />
          De como llegar a ser la persona que decidiste amar. <br />
          Es momento de que eches a volar. <br />
          De esta mente que jamás te olvidará. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Este corazón herido
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Octubre 25, 2019.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Como curar este corazón herido. <br />
          Que tras amar sin mesura terminó roto y abatido. <br />
          Que tonto fui al pensar que me vería como algo más. <br />
          Cuando solo me veía como un amigo nada más. <br />
          Resulta injusto el amar y no ser correspondido. <br />
          Más aún después de todos los preparativos. <br />
          Y ver como esta realidad nos devuelve abruptamente al piso. <br />
          No está todo mal, mientras la pueda ver crecer y brillar. <br />
          Aunque otro sea el motivo de toda aquella felicidad. <br />
          Aunque yo tenga que acallar toda esta verdad. <br />
          Y aceptar esta desventura a la que llamamos realidad. <br />
          Una voz me dice no te rindas. <br />
          Creo que llegó el momento de dejar estas tristes rimas. <br />
          Y vivir con este tormento hasta el fin de mis días. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            La poesía se ha dejado
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Octubre 11, 2018.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Me han comentado que la poesía se ha dejado. <br />
          Que hemos dejado de escribírsela al amado. <br />
          Desde hace cuánto se ha considerado demasiado. <br />
          El escribir poesía en estos tiempos malaventurados. <br />
          Me pregunto, ¿por qué esto fue aceptado sin pensarlo? <br />
          Habremos olvidado acaso cómo se siente estar enamorado. <br />
          O será acaso porque tenemos miedo a ser rechazados. <br />
          En fin, espero algún día este error sea enmendado. <br />
          Para escribirte a ti que te encuentras a mi lado. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Qué bonito es el amor
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Octubre 10, 2018.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Qué bonito es el amor, aunque no toque a tu puerta y (cuando lo hace) a veces te hiera. <br />
          Aunque te ilusione y a veces te decepcione. <br />
          Aunque te de alas y después te las corte. <br />
          Aunque te haga suspirar por (alguien) algo imposible, por un amor imposible. <br /><br />

          Qué bonito es el amor, aunque te haga llorar cuando decidiste volver a confiar. <br />
          (Porque) Aunque te haga regresar (incluso) cuando te prometiste no volver a amar. <br />
          Aunque te haga enojar, pelear y perdonar. <br />
          (Porque, aunque) Aunque te lastime, tu volverás a amar. <br /><br />

          Qué bonito es el amor a pesar de todo el dolor. <br />
          A pesar de cualquier horrible error. <br />
          A pesar de dar mucho y recibir poco. <br />
          A pesar de todo, qué bonito es el amor. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Oda al amor
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Octubre 8, 2018.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Me gusta esa energía con la que despiertas cada día. <br />
          Me gusta esa sonrisa que veo en tu rostro día con día. <br />
          Me gusta esa forma de vestir tuya, que enamora a primera vista. <br />
          Me gusta ese arte, así como me gusta el ser capaz de amarte. <br />
          Me gusta esa mirada cuando la encuentro con la guardia baja. <br />
          Me gustan esos labios finos y delgados, ojalá y pudiera besarlos. <br />
          Me gusta ese sentir cuando finalmente, me encuentro junto a ti. <br />
          Me gusta esa alegría que compartes con el mundo todos los días. <br />
          Me gusta esa inspiración que me hizo escribirte esta declaración. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Rindo esta, mi humilde declaración
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Septiembre 17, 2018.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Cada día extraño más el lugar llamado hogar. <br />
          Insensible fui al marcharme sin mirar atrás. <br />
          No creí que todo esto iba a causar. <br />
          Todo por no pararme a pensar. <br />
          Hoy me encuentro a la deriva. <br />
          Y trato de recordar la razón de mi partida. <br />
          Acaricio mi barbilla recordando a esa dulce pequeñita. <br />
          Quisiera estar contigo. <br />
          Unidos, como la pelusa y el ombligo. <br />
          Imperfecto he sido, mas mi sentir resulta ser el mismo. <br />
          Enterrarme en tu pecho, esperando que esto sea algo más que un simple sueño. <br />
          Recordando aquellas tardes de besarnos en los suelos. <br />
          O aquella primera vez que me dijiste un te quiero. <br />
          De nada sirve recordar aquellas viejas memorias. <br />
          En especial esas que cuentan toda nuestra historia. <br />
          Cambiaría todo el oro del mundo, con tal de verte tan solo un minuto. <br />
          Imbécil fui, por pensar que estaría mejor sin ti. <br />
          Responder a mis actos debo, porque es lo correcto. <br />
          Todos, y cada uno de ellos. <br />
          Espero el día en que sea perdonado de todos estos pecados. <br />
          Quiero hacer memoria de ese día. <br />
          Un frío invierno, mi mente respondía. <br />
          En ese momento mi corazón de a poco se moría. <br />
          Mi suerte ya pronto estaría escrita. <br />
          Ese día firmaste aquella horrible responsiva. <br />
          Gracioso es que no pude hablar contigo por última vez. <br />
          Un error mío, provocó esa estupidez. <br />
          Si pudiera, me volvería al ayer. <br />
          Trataría de enmendar lo que no hice aquella vez. <br />
          Aceptar es sinónimo de sensatez. <br />
          Solo espero que algún día perdones a este tonto ser. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Y aquí vamos de nuevo...
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Agosto 3, 2018.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Será acaso que la volveré a ver. <br />
          O será que todo ha terminado. <br />
          Veo tu foto y deseo que todo fuese un sueño. <br />
          No pensé que por creer te llegaría a perder. <br /><br />

          No puedo sacarte de mi mente. <br />
          Increíble es, que en poco tiempo vulneraras mi impenetrable armazón. <br />
          Y más importante aún mi corazón. <br />
          Tu bello cabello y hermosa sonrisa resultaron mejor que cualquier solvente. <br /><br />

          Si tan solo lo hubiera sabido. <br />
          Jamás te habría contado aquello que sigo sintiendo. <br />
          Daría lo que fuera por volverte a ver. <br />
          No quiero perder, por favor, hazme volver a creer. <br /><br />

          No tiene caso. <br />
          Porque ya me has olvidado. <br />
          Ojalá no me hubiera declarado. <br />
          Y así nunca me habrías odiado. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={10}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            No he cambiado
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Mayo 27, 2018.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Mis sentimientos por ti no han muerto. <br />
          Negarlos, terrible daño me ha hecho. <br />
          Poder hablarte de esto es mi más profundo sueño. <br />
          Mas no lo hago para evitar sufrir tu rechazo. <br />
          Espero algún día que estés entre mis brazos. <br />
          Para poderte decir cuánto te amo. <br />
          Estos sentimientos callar debo. <br />
          Porque perderte miedo tengo. <br />
          Será que algún día se cumpla mi sueño. <br />
          Y pueda expresarte lo mucho que siento. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={11}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Sin título
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Diciembre 28, 2017.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          Camino en la acera sintiéndome abatido. <br />
          Desde el día en que me dejaste la vida no tiene sentido. <br />
          Tras haber hecho tus maletas, te fuiste sin siquiera haberte despedido. <br />
          Anhelar tu retorno a pesar de estar perdido. <br /><br />

          Doblo la esquina con la esperanza latente. <br />
          Porque mi único deseo es volver a verte. <br />
          Tantos entes como cosas en mi mente. <br />
          No entiendo esta soledad creciente. <br /><br />

          Los pasillos escolares ya no son los mismos. <br />
          Sin esa sonrisa que iluminaba todos mis caminos. <br />
          No es justa la jugada del destino. <br />
          Responsable fue de que hayas partido. <br />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={12}>
        <Box fontStyle='italic' p={2}>
          <Typography
            variant='h3'
          >
            Un caballero empedernido con el amor
          </Typography>
          <Typography
            variant='h5'
            align='left'
          >
            Fabián Camp: Noviembre 18, 2017.
          </Typography>
        </Box>
        <br />
        <Typography
          variant='body1'
          align='left'
        >
          El tiempo pasaba y yo la miraba. <br />
          Poco a poco más me gustaba. <br />
          ¿Quién es ella? Me cuestionaba. <br />
          Tengo que ir a hablarle me animaba. <br /><br />

          Difícil hablarte es. <br />
          Porque un ángel resultaste ser. <br />
          No dejar de verte el mejor pasatiempo que pude tener. <br />
          Ese día mi vida cambio y te lo agradezco de una buena vez. <br /><br />

          No siempre las cosas marchan bien. <br />
          Y aquel día fue peor que un golpe en la cien. <br />
          Imaginarte en mi posición la opción nunca fue. <br />
          Simplemente mis sentimientos empaqué. <br /><br />

          El reloj continuó su camino. <br />
          Un futuro sin ti parecía ser mi destino. <br />
          Sin previo aviso esa carta cambio mi sentido. <br />
          Y a tu encuentro salí enloquecido. <br /><br />

          Ese momento llegó. <br />
          Nuestra relación finalmente se forjó. <br />
          La defenderé como a esos antiguos reinos. <br />
          Como el acero durante el Medievo. <br /><br />

          Esta es nuestra historia juntos. <br />
          Cumplimos nuestros sueños. <br />
          Me diste esos hermosos hijos. <br />
          Que me recordaron que el destino entrelazó nuestros caminos. <br /><br />

          Efímera es la vida y fue triste tu partida. <br />
          Nuestros hijos son mi única salida. <br />
          Sin ti, mi vida resulta demasiado nítida. <br />
          Volver a verte es el deseo del resto de mi vida. <br />
        </Typography>
      </TabPanel>
    </div>
  );
}