import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NavBarComponent from './components/NavigationBar/NavBar';
import HomeComponent from './components/Homepage/Home';
import AboutComponent from './components/AboutPage/About';
import WritingComponent from './components/WritingsPage/Writing';
import GuideComponent from './components/GuidePage/Guide';
import ContactComponent from './components/ContactPage/Contact';
import FooterPageComponent from './components/Footer/FooterPage';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#0388A6',
      main: '#024059',
      dark: '#012c3e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#35abb7',
      main: '#0396A6',
      dark: '#026974',
      contrastText: '#fff',
    },
  },
});

function createData(key, path, componentToMount) {
  return { key, path, componentToMount };
}

const routerRoutes = [
  // Default Routes.
  createData(0, '/', <HomeComponent />), // Home page component.
  createData(1, '/about', <AboutComponent />), // AboutMe page component.
  createData(2, '/writings', <WritingComponent />), // Writings page component.
  createData(3, '/guides', <GuideComponent />), // Guides page component.
  createData(4, '/contact', <ContactComponent />), // Contact page component.
];

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Switch>
          {routerRoutes.map((route) => (
            <Route exact path={route.path}>
              <NavBarComponent />
              {route.componentToMount}
              <FooterPageComponent />
            </Route>
          ))}
          <Redirect to="/" />
        </Switch>
      </ThemeProvider>
    </div>
  );
}

export default App;
