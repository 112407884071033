import React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import FabianImage from '../../images/fabianCamp.jpeg';
import ResumeEnglish from '../../files/fabianCV_en.pdf';
import ResumeSpanish from '../../files/fabianCV_es.pdf';

var dateBirth = new Date().getFullYear() - 1998;

function calculateDob(){
  var month = new Date().getMonth();
  if (month <= 4) {
    dateBirth--;
  }
};

export default function AboutComponent() {
  calculateDob();
  return (
    <div>
      <Box p={2} />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box p={4} />
          <Typography
            align='center'
            variant='h3'
          >
            ¡Hola a todos!
          </Typography>
          <Box p={2} />
          <Typography
            align='center'
            variant='body1'
          >
            Mi nombre es Fabián Camp Mussa,
            tengo {dateBirth} de edad.
            <br />
            Actualmente estoy trabajando en 
            Konfio y soy egresado de la
            carrera de <i>Ingeniería en
            Sistemas Computacionales</i>.
            <br />
            Organicé el HackMx(2019) junto con
            la asociación de estudiantes y el
            Tec de Monterrey.
            <br />
            Me gustan mucho los videojuegos, leer,
            escribir y crear memes.
            <br />
            He escrito varios poemas y una novela
            que publicaré algún día.
          </Typography>
          <Box p={4} />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Button
                color='secondary'
                href={ResumeEnglish}
                style={{ textTransform: 'none' }}
                variant='contained'
                target='_blank'
                size='large'
              >
                Consulta mi C. V. (Inglés)
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                color='secondary'
                href={ResumeSpanish}
                style={{ textTransform: 'none' }}
                variant='contained'
                target='_blank'
                size='large'
              >
                Consulta mi C. V. (Español)
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            alt='Imagen de Fabián'
            src={FabianImage}
            height={580}
          />
        </Grid>
      </Grid>
    </div>
  );
}