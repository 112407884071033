import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Card, CardContent, CardMedia, CardActions } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import GithubLogo from '../../images/github.png';
import LinkedInLogo from '../../images/linkedIn.png';
import EmailLogo from '../../images/email.png';

const useStyles = makeStyles({
  root: {
    maxWidth: 375,
  },
  media: {
    height: 375,
  },
});

export default function ContactComponent(props) {

  const classes = useStyles();

  return (
    <div>
      <Box p={2} m={5}>
        <Grid container spacing={5}>
          <Grid item xs={8} sm={4}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.media}
                image={GithubLogo}
                title='Github Logo'
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Checa mi Github
                </Typography>
                <Divider />
                <br />
                <Typography variant="body2" color="textSecondary" component="p" align='left'>
                  Aquí encontrarás los proyectos en los que estoy trabajando.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="secondary"
                  size="small"
                  style={{ textTransform: "none" }}
                  variant='contained'
                  href='https://github.com/fcampm'
                >
                  Sígueme en Github
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.media}
                image={LinkedInLogo}
                title='LinkedIn Logo'
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Checa mi LinkedIn
                </Typography>
                <Divider />
                <br />
                <Typography variant="body2" color="textSecondary" component="p" align='left'>
                  Aquí encontrarás más información sobre mi vida profesional.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="secondary"
                  size="small"
                  style={{ textTransform: "none" }}
                  variant='contained'
                  href='https://www.linkedin.com/in/fabián-camp-mussa-2a8a691b1'
                >
                  Sígueme en LinkedIn
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.media}
                image={EmailLogo}
                title='Email logo'
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Mándame un e-mail
                </Typography>
                <Divider />
                <br />
                <Typography variant="body2" color="textSecondary" component="p" align='left'>
                  Responderé lo más pronto posible,
                  soy una persona muy distraída.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="secondary"
                  size="small"
                  style={{ textTransform: "none" }}
                  variant='contained'
                  href='mailto:fabiancamp98@gmail.com'
                >
                  Mándame un correo
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

}